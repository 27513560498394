import Postagem from "@/interfaces/Postagem";

const PostagemColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "texto",
    align: "left",
    label: "Texto",
    field: (row: Postagem) => {
      if (row.texto.length > 40) {
        return row.texto.substr(0, 40) + "...";
      }
      return row.texto;
    },
    sortable: true,
  },
  {
    name: "relacionado",
    align: "center",
    label: "Relacionado",
    field: (row: Postagem) => {
      if (row.turmas.length > 0) {
        return row.turmas.map(t => t.nome);
      } else if (row.cursos.length > 0) {
        return row.cursos.map(t => t.descricao);
      }
      return ["Geral"];
    },
    sortable: false,
  },
  {
    name: "fixado",
    align: "center",
    label: "Postagem Fixada",
    field: "data_fixado",
    sortable: true,
  },
  {
    name: "fixado",
    align: "center",
    label: "Postagem Exibição",
    field: "data_exibicao",
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Postagem) => row.created_at,
    sortable: true,
  },
];

export default PostagemColumn;
