import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostagemForm = _resolveComponent("PostagemForm")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PostagemForm, {
      cursos: _ctx.cursos,
      turmas: _ctx.turmas,
      onUpdate: _ctx.list,
      ref: "modalForm"
    }, null, 8, ["cursos", "turmas", "onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Postagens",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-texto": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    innerHTML: props.value
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode("-")
                  ], 64))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-relacionado": _withCtx((props) => [
          (props.row.turmas.length > 0)
            ? (_openBlock(), _createBlock(_component_q_td, {
                key: 0,
                props: props
              }, {
                default: _withCtx(() => [
                  (props.row.turmas.length == 1)
                    ? (_openBlock(), _createBlock(_component_q_badge, {
                        key: 0,
                        color: "primary",
                        label: props.row.turmas.length + ' TURMA'
                      }, null, 8, ["label"]))
                    : (_openBlock(), _createBlock(_component_q_badge, {
                        key: 1,
                        color: "primary",
                        label: props.row.turmas.length + ' TURMAS'
                      }, null, 8, ["label"]))
                ]),
                _: 2
              }, 1032, ["props"]))
            : (props.row.cursos.length > 0)
              ? (_openBlock(), _createBlock(_component_q_td, {
                  key: 1,
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.cursos.length == 1)
                      ? (_openBlock(), _createBlock(_component_q_badge, {
                          key: 0,
                          color: "secondary",
                          label: props.row.cursos.length + ' CURSOS'
                        }, null, 8, ["label"]))
                      : (_openBlock(), _createBlock(_component_q_badge, {
                          key: 1,
                          color: "secondary",
                          label: props.row.cursos.length + ' CURSOS'
                        }, null, 8, ["label"]))
                  ]),
                  _: 2
                }, 1032, ["props"]))
              : (_openBlock(), _createBlock(_component_q_td, {
                  key: 2,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_badge, {
                      color: "orange",
                      label: 'GERAL'
                    })
                  ]),
                  _: 2
                }, 1032, ["props"]))
        ]),
        "body-cell-fixado": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (_ctx.verifyIsFixed(props.value))
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "green",
                    label: "FIXADA"
                  }))
                : (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "negative",
                    label: "NÃO FIXADA"
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "green",
                    label: "ATIVO"
                  }))
                : (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "negative",
                    label: "INATIVO"
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-created_at": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "edit",
                onClick: ($event: any) => (_ctx.openModalForm(props.row)),
                disable: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Editar ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onClick", "disable"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "red",
                "icon-right": "delete",
                disable: _ctx.loading,
                onClick: ($event: any) => (_ctx.confirmDelete(props.row.id, props.row.descricao))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Excluir ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}